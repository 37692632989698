import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import directionsIcon from '../../assets/tripPage/directions-solid.svg';

import css from './TripPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { className, rootClassName, location, geolocation, showAddress, isProvider, customAddress, needDirection = true, showExactShuLocation, partialLocation, currentTransaction } = props;



  const { address, building, floorUnit } = (typeof location === 'object' && location !== null) ? location : { address: location || "" };
  const partialAddress = partialLocation ? partialLocation: address ? address?.split(',')[1] : "";

  const isDelivery =
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.isDelivery) ||
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.isDelivery);
  const customerLocation =
    currentTransaction &&
    currentTransaction.attributes &&
    currentTransaction.attributes.protectedData &&
    currentTransaction.attributes.protectedData.customerLocation &&
    currentTransaction.attributes.protectedData.customerLocation.selectedPlace;


  const customerFullAddress = `${customerLocation.floorUnit || ''} ${customerLocation.address ||
    ''} ${customerLocation.postalCode || ''}`;


  const { lat, lng } = geolocation || {};
  const hrefToGoogleMaps = isDelivery
    ? `https://maps.google.com/?q=${customerLocation.origin.lat},${customerLocation.origin.lng}`
    : geolocation
      ? `https://maps.google.com/?q=${lat},${lng}`
      : address
        ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
        : null;

  const classes = classNames(rootClassName || css.address, className);
  return showAddress && hrefToGoogleMaps ? (
    <div className={classes}>
      {/* {isProvider ? (
        <p className={css.deliveryText}>
          <FormattedMessage id="TransactionPanel.deliveryText" />
        </p>
      ) : null} */}
      {showExactShuLocation || isDelivery ?
        (
          <div className={css.addressContainer}>

            {/* {customAddress ? customAddress : ( */}
            <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
                          href={hrefToGoogleMaps}>
              {isDelivery ? (<span>{customerFullAddress}</span>) : (<span>{address}</span>)}
            </ExternalLink>
            {/* )} */}

            {needDirection && <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
              <img src={directionsIcon} alt="direction" className={css.mapImageDirection}/>
            </ExternalLink>}
          </div>

        ) :
        <div className={css.addressContainer}>

          {/* {customAddress ? customAddress : ( */}
          <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
                        href={hrefToGoogleMaps}>
            <span>{partialAddress}</span>
          </ExternalLink>
          {/* )} */}

          {/* {needDirection &&
          <div className={css.mapImgContainer}>
          <img
            src={directionsIcon}
            alt="direction"
            className={`${css.mapImageDirection} ${css.disabled} ${css.cursorDisabled}`}
          />
        </div>

          } */}
        </div>
      }

    </div>
  ) : (
    <div className={classes}>
      <p className={css.deliveryText}>
        <FormattedMessage id="TransactionPanel.locationText" />
      </p>
    </div>
  );
};

export default AddressLinkMaybe;
