import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { debounce, isEqual, unionWith, get } from 'lodash';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageVisibilitySearchMap,
} from '../../ducks/UI.duck';
import { ModalInMobile, Page, SearchMap } from '../../components';
import { TopbarContainer } from '../../containers';
import {
  initiateEventFromListing,
  initiateEventViewedFromListing,
  pushGTMSearchUsedMap,
} from '../../util/gtm/gtmHelpers';
import { gSend, getHourlyFormattedTimes, getFormattedDateByDates, sendG4AEvent, GTAG_ACTIONS } from '../../util/gtag';
import {
  EVENT_SEARCH_PERFORMED,
  EVENT_SEARCH_USED_MAP,
  EVENT_SEARCH_VIEWED_LISTING_GUEST,
  EVENT_VIEWED_PRODUCT,
} from '../../util/gtm/gtmConstants';
import { ensureListing } from '../../util/data';

import {
  newCarSearchListings,
  searchListings,
  searchMapListings,
  setActiveListing,
} from './SearchPage.duck';
import {
  createSearchResultSchema,
  pickSearchParamsOnly,
  validFilterParams,
  validURLParamsForExtendedData,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import moment from 'moment';
import { withViewport } from '../../util/contextHelpers';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 50;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
// const SEARCH_WITH_MAP_DEBOUNCE = 0;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      gtmEvents: [],
      gtmEventsExp: []
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  getNewCarSearch = () => {
    const { location, onNewCarSearchListings } = this.props;
    if (location && location.search) {
      const queryParams = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      let { sort: sortParameter } = queryParams;

      console.log("props new", this.props);
      console.log("Experiment B running 1");
      sortParameter = 'pub_attractiveScore'
      //Sending segment event exp B
      try {
        console.log("Search userd map tttttttt12");
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.EXPERIMENT_NORMALISED_ATTRACTIVENESS_EXPERIMENT_B,
          eventData: this.state.gtmEventsExp,
          props:
            {
              guest: this.props.currentUser,
              search: {
                ...this.props.searchParams
              }
            },
          userId: this.props.currentUser ?  this.props.currentUser.id.uuid : null
        });
      } catch (error) {
        console.log('Error fetching events:', error);
      }

      const { page = 1, sort = sortParameter, address, origin, ...rest } = queryParams;
      onNewCarSearchListings({
        ...rest,
        origin,
        address,
        page,
        'meta_isNewCar': true,
        sort: sortParameter,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      });
    }
  };

  async componentDidMount() {
    try {
      const response = await getEventsByBucket('search');
      const responseExp = await getEventsByBucket('experiment');
      console.log("responseExp", responseExp);
      // console.log('Events fetched successfully:', response.data);
      this.setState({
        gtmEvents: response.data,
        gtmEventsExp: responseExp.data,
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
    this.props.onManageDisableScrolling(true);
    this.getNewCarSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const {
      bounds: nextBounds,
      'fields.image': nextFieldsImage,
      'fields.user': nextFieldsUser,
      sort: nextSort,
      'limit.images': nextLimitImage,
      mapSearch: nextMapSearch,
      page: nextPage,
      perPage: nextPerPage,
      'fields.listing': nextFieldsListing,
      include: nextInclude,
      ...nextSearchParams
    } = this.props.searchParams || {};
    const {
      bounds: prevBounds,
      'fields.image': fieldsImage,
      'fields.user': prevFieldsUser,
      sort: prevSort,
      'limit.images': prevLimitImage,
      mapSearch: prevMapSearch,
      page: prevPage,
      perPage: prevPerPage,
      'fields.listing': prevFieldsListing,
      include: prevInclude,
      ...prevSearchParams
    } = prevProps.searchParams || {};
    if (!isEqual(nextSearchParams, prevSearchParams)) {
      // console.log('search page event');
      // initiateEventFromListing({
      //   props: this.props,
      //   event: EVENT_SEARCH_PERFORMED,
      // });
      const searchParams = this.props.searchParams || {};
      const hours = getHourlyFormattedTimes(searchParams.hours);
      const dates = getFormattedDateByDates(searchParams.dates);
      gSend(GTAG_ACTIONS.ACTION_SEARCH, {
        // TODO: Assign proper details.
        search_location: `${searchParams.address || ''}`,
        search_pickupdate: `${dates.start ? dates.start : ''}`,
        search_pickuptime: `${hours.start ? hours.start : ''}`,
        search_dropoffdate: `${dates.start ? dates.start : ''}`,
        search_dropofftime: `${hours.end ? hours.end : ''}`,
      });
    }
    if ((prevProps.location && location) && prevProps.location.search !== location.search) {
    }
  }

  filters() {
    const {
      categories,
      reviews,
      brandNames,
      features,
      transmissions,
      typeOfFuel,
      canDriveToMalaysia,
      isPetFriendly,
      priceFilterConfig,
      distanceFilterConfig,
      homeDeliveryOptions,
      numberPassengers,
      dateRangeFilterConfig,
      keywordFilterConfig,
      isInstantBookingConfig,
      isPHVFilterOptions,
      drivelahGoOptions,
      superHostOptions,
      topOfferOptions,
      disinfectedOptions,
      longTermOptions,
      isSearchingLongTerm,
      // numOfSeatsOptions,
    } = this.props;

    return {
      categoryFilter: {
        paramName: 'pub_category',
        options: categories,
      },
      reviewsFilter: {
        paramName: 'pub_reviews',
        options: reviews,
      },
      brandNamesFilter: {
        paramName: 'pub_brandName',
        options: brandNames,
      },
      featuresFilter: {
        paramName: 'pub_keyFeatures',
        options: features,
      },
      transmissionsFilter: {
        paramName: 'pub_transmissions',
        options: transmissions,
      },
      typeOfFuelFilter: {
        paramName: 'pub_fuelType',
        options: typeOfFuel,
      },
      canDriveToMalaysiaFilter: {
        paramName: 'pub_canDriveToMalaysia',
        options: canDriveToMalaysia,
      },
      isPetFriendlyFilter: {
        paramName: 'pub_isPetFriendly',
        options: isPetFriendly,
      },
      distanceFilter: {
        paramName: 'distance',
        config: distanceFilterConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      numberPassengerFilter: {
        paramName: 'pub_peopleNumberMax',
        config: numberPassengers,
      },
      dateRangeFilter: {
        paramName: 'dates',
        config: dateRangeFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
      instantBookingFilter: {
        paramName: 'pub_instantBooking',
        config: isInstantBookingConfig,
        isBoolean: true,
      },
      phvFilter: {
        paramName: 'pub_isPHV',
        options: isPHVFilterOptions,
      },
      // numOfSeatsFilter: {
      //   paramName: 'pub_peopleNumberMax',
      //   options: numOfSeatsOptions,
      // },
      drivelahGoFilter: {
        paramName: 'meta_isDrivelahGo',
        options: drivelahGoOptions,
        isBoolean: true,
      },
      homeDeliveryFilter: {
        paramName: 'pub_delivery',
        options: homeDeliveryOptions,
        isBoolean: true,
      },
      superHostFilter: {
        paramName: 'pub_isSuperHost',
        options: superHostOptions,
        isBoolean: true,
      },
      // longTermFilter: {
      //   paramName: 'pub_longTermRental',
      //   options: longTermOptions,
      //   isBoolean: true,
      // },
      topOfferFilter: {
        paramName: 'meta_isNewCar',
        options: topOfferOptions,
        isBoolean: true,
      },
      disinfectedFilter: {
        paramName: 'meta_isDisinfected',
        options: disinfectedOptions,
        isBoolean: true,
      },
      hoursFilter: {
        paramName: 'hours',
        isBoolean: true,
      },
    };
  }

  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, currentUser } = this.props;
      // Here we have to fetch the search bucket events

      // pushGTMSearchUsedMap(currentUser, EVENT_SEARCH_USED_MAP);
      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, hours, locationName, timezone, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        hours,
        timezone,
        mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };

      try {
        // console.log("Search userd map tttttttt");
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.SEARCH_USED_MAP,
          eventData: this.state.gtmEvents,
          props:
          {
            guest: currentUser,
            search: {
              ...searchParams
            }
          },
          userId: currentUser ?  currentUser.id.uuid : null
        });
      } catch (error) {
        console.log('Error fetching events:', error);
      }

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onSelectCard = (selectedListing, isUsedMap) => {
    const currentListing = ensureListing(selectedListing);
    // initiateEventFromListing({
    //   props: this.props,
    //   listing: currentListing,
    //   event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
    //   isHost: false,
    // });
    // initiateEventFromListing({
    //   props: this.props,
    //   listing: currentListing,
    //   event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
    //   isHost: false,
    // });
    const host = currentListing && currentListing.author;
    // console.log("Adding the venueYYYYYYYYYYYYYY", host)
    // triggerAnalyticsEvent({
    //   event_id: event_trigger_ids.SEARCH_VIEWED_LISTING_GUEST,
    //   eventData: this.state.gtmEvents,
    //   props: {
    //     guest: this.props.currentUser,
    //     host: host,
    //     search: this.props.searchParams,
    //     currentListing
    //   },
    //   user: this.props.currentUser,
    //   isBackendApiCall: true,
    //   userId: this.props.currentUser && this.props.currentUser.id && this.props.currentUser.id.uuid
    //  });

    // triggerAnalyticsEvent({
    //   event_id: event_trigger_ids.SEARCH_VIEWED_LISTING_HOST,
    //   eventData: this.state.gtmEvents,
    //   props: {
    //     guest: this.props.currentUser,
    //     host : host,
    //     search: this.props.searchParams,
    //     currentListing
    //   },
    //   isBackendApiCall: true,
    //   userId: host && host.id && host.id.uuid
    // });
    sendG4AEvent(currentListing, GTAG_ACTIONS.ACTION_VIEW_ITEM, this.props);
    // initiateEventViewedFromListing({
    //   props: this.props,
    //   listing: currentListing,
    //   event: EVENT_VIEWED_PRODUCT,
    //   isHost: false,
    // });
  };

  handleSearchDaysGreaterThan60Days = (date, isLongTerm = false) => {
    const { location, history } = this.props;
    const { mapSearch, page, sort, ...urlQueryParams } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    this.currentDates = date;
    const [startString, endString] = date.split(',');
    const diff =
      startString &&
      endString &&
      moment(endString, 'YYYY-MM-DD').diff(moment(startString, 'YYYY-MM-DD'), 'days', true);

    // if (diff && diff > 60 && !isLongTerm) {
    const queryParams = { ...urlQueryParams };

    //   history.push(
    //     createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
    //   );
    // } else if (diff && diff <= 60 && isLongTerm) {
    //   const queryParams = omit({ ...urlQueryParams }, ['pub_longTermRental']);

    //   history.push(
    //     createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
    //   );
    // }
    createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams);
  };

  render() {
    const {
      intl,
      listings,
      newCarPageListings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      onManageVisibilitySearchMap,
      visibilityMap,
      isSearchingLongTerm,
      currentUser,
      viewport,
      center,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    if (!location || !location.search) {
      location.search =
        '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999';
    }
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);
    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const searchTimes = searchInURL && searchInURL.hours ? searchInURL.hours : null;
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={false}
        description={description}
        title={title}
        schema={schema}
        className={css.page}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage='SearchPage'
          currentSearchParams={urlQueryParams}
          mobileClassName={css.mobileTopbarContainer}
          contactUsClassName={css.contactUs}
          isMobileLayout={isMobileLayout}
        />
        <div className={css.container}>
          <MainPanel
            isSearchingLongTerm={isSearchingLongTerm}
            currentUser={currentUser}
            searchDate={searchInURL && searchInURL.dates ? searchInURL.dates : null}
            searchTimes={searchTimes}
            urlQueryParams={validQueryParams}
            listings={listings}
            center={center}
            newCarPageListings={newCarPageListings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            primaryFilters={{
              categoryFilter: filters.categoryFilter,
              // distanceFilter: filters.distanceFilter,
              priceFilter:
                isSearchingLongTerm || validQueryParams.pub_longTermRental
                  ? null
                  : filters.priceFilter,
              // dateRangeFilter: filters.dateRangeFilter,
              drivelahGoFilter: filters.drivelahGoFilter,
              superHostFilter: filters.superHostFilter,
              homeDeliveryFilter: filters.homeDeliveryFilter,
              canDriveToMalaysiaFilter: filters.canDriveToMalaysiaFilter,
              isPetFriendlyFilter: filters.isPetFriendlyFilter,
              instantBookingFilter:
                isSearchingLongTerm || validQueryParams.pub_longTermRental
                  ? null
                  : filters.instantBookingFilter,
              topOfferFilter: filters.topOfferFilter,
              disinfectedFilter: filters.disinfectedFilter,
              isPHVFilter: filters.phvFilter,
              // numOfSeatsFilter: filters.numOfSeatsFilter,
            }}
            secondaryFilters={{
              categoryFilter: filters.categoryFilter,
              // distanceFilter: filters.distanceFilter,
              priceFilter:
                isSearchingLongTerm || validQueryParams.pub_longTermRental
                  ? null
                  : filters.priceFilter,
              reviewsFilter: filters.reviewsFilter,
              brandNamesFilter: filters.brandNamesFilter,
              featuresFilter: filters.featuresFilter,
              transmissionsFilter: filters.transmissionsFilter,
              typeOfFuelFilter: filters.typeOfFuelFilter,
              canDriveToMalaysiaFilter: filters.canDriveToMalaysiaFilter,
              isPetFriendlyFilter: filters.isPetFriendlyFilter,
              numberPassengerFilter: filters.numberPassengerFilter,
              keywordFilter: filters.keywordFilter,
              instantBookingFilter:
                isSearchingLongTerm || validQueryParams.pub_longTermRental
                  ? null
                  : filters.instantBookingFilter,
              isPHVFilter: filters.phvFilter,
              // numOfSeatsFilter: filters.numOfSeatsFilter,
              drivelahGoFilter: filters.drivelahGoFilter,
              superHostFilter: filters.superHostFilter,
              homeDeliveryFilter: filters.homeDeliveryFilter,
            }}
            isMobileLayout={isMobileLayout}
            visibilityMap={visibilityMap}
            onManageVisibilitySearchMap={onManageVisibilitySearchMap}
            location={location}
            sort={sort}
            onSelectCard={this.onSelectCard}
            viewport={viewport}
          />
          {(visibilityMap || isMobileLayout) &&
            !(validQueryParams.pub_longTermRental || isSearchingLongTerm) && (
              <ModalInMobile
                className={css.mapPanel}
                id='SearchPage.map'
                isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.mapWrapper}>
                  {this.state.isSearchMapOpenOnMobile || shouldShowSearchMap ? (
                    <SearchMap
                      searchDate={searchInURL && searchInURL.dates ? searchInURL.dates : null}
                      searchTimes={searchTimes}
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      intl={intl}
                      bounds={bounds}
                      center={origin}
                      isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                      location={location}
                      listings={mapListings || []}
                      onMapMoveEnd={this.onMapMoveEnd}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('SearchPage.map', false);
                      }}
                      messages={intl.messages}
                      onSelectCard={this.onSelectCard}
                      currentUser={currentUser}
                    />
                  ) : null}
                </div>
              </ModalInMobile>
            )}
        </div>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categories: config.custom.categories,
  reviews: config.custom.reviews,
  brandNames: config.custom.brands,
  features: config.custom.keyFeatures.filter(
    f => f.key !== 'air_conditioning' && f.key !== 'sunroof',
  ),
  transmissions: config.custom.transmissions,
  typeOfFuel: config.custom.fuelTypeFilters,
  canDriveToMalaysia: config.custom.canDriveToMalaysia,
  isPetFriendly: config.custom.isPetFriendly,
  isInstantBookingConfig: config.custom.isInstantBooking,
  numberPassengers: config.custom.numberPassengerConfig,
  distanceFilterConfig: config.custom.distanceFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  dateRangeFilterConfig: config.custom.dateRangeFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  isPHVFilterOptions: config.custom.isPHV,
  drivelahGoOptions: config.custom.yesNoFilterOptions,
  superHostOptions: config.custom.yesNoFilterOptions,
  homeDeliveryOptions: config.custom.yesNoFilterOptions,
  topOfferOptions: config.custom.yesNoFilterOptions,
  disinfectedOptions: config.custom.yesNoFilterOptions,
  activeListingId: null,
  longTermOptions: config.custom.yesNoFilterOptions,
  // numOfSeatsOptions: config.custom.numOfSeats,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categories: array,
  reviews: array,
  brandNames: array,
  features: array,
  transmissions: array,
  typeOfFuel: array,
  canDriveToMalaysia: bool.isRequired,
  isPetFriendly: bool.isRequired,
  isInstantBookingConfig: bool.isRequired,
  topOfferOptions: bool.isRequired,
  disinfectedOptions: bool.isRequired,
  numberPassengers: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  distanceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeFilterConfig: shape({ active: bool.isRequired }),
  currentUser: propTypes.currentUser,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    isSearchingLongTerm,
    newCarCurrentPageResultIds,
  } = state.SearchPage;
  const { currentUser } = state.user;


  const DEFAULT_BOUNDS = {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: 1.4708809,
      lng: 104.04157989999999,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: 1.216611,
      lng: 103.60650989999999,
    },
  };

  //Using default bounds if bounds not present
  const bounds = searchParams && searchParams.bounds || DEFAULT_BOUNDS;

  let center;
  try {
    center = {
      lat: (bounds.ne.lat + bounds.sw.lat) / 2,
      lng: (bounds.ne.lng + bounds.sw.lng) / 2,
    };
  } catch (e) {
    console.log('unable to set bound', e);
  }

  const pageListings = getListingsById(state, currentPageResultIds);
  const newCarPageListings = getListingsById(state, newCarCurrentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid),
  );

  return {
    currentUser,
    visibilityMap: state.UI.visibilityMap,
    listings: pageListings,
    newCarPageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    center,
    activeListingId,
    isSearchingLongTerm,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onManageVisibilitySearchMap: () => dispatch(manageVisibilitySearchMap()),
  onNewCarSearchListings: (searchParams) => dispatch(newCarSearchListings(searchParams)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  let { sort: sortParameter } = queryParams;


  let sortQuery;

  if (!sortParameter) {
    sortParameter = 'relevance';
  }

  sortQuery = 'pub_attractiveScore'

  const { page = 1, sort = sortParameter, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const sortRelevenceOption = sort === 'relevance' ? sortQuery : sort;
  return searchListings({
    ...rest,
    ...originMaybe,
    // gtmEvents: this.state.gtmEvents,
    origin,
    address,
    page,
    sort: sortRelevenceOption,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default SearchPage;
